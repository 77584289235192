import React, { FC } from 'react';
import './description.css'
import * as countertopImage from '../../images/ps-countertop.svg'

interface DescriptionProps {
    bgColor: string;
}

const DescriptionSection: FC<DescriptionProps> = (props) => {
    return (
        <section id="description" className="p-4 md:p-8" style={{ backgroundColor: props.bgColor }}>
            <div className="flex flex-row flex-wrap md:flex-row-reverse items-center xl:max-w-container xl:mx-auto doI-container">
                <div className="w-full md:w-2/5">
                    <img className="w-full " src={countertopImage} alt="Countertop Kiosk Outline" />
                </div>
                <div className="md:w-3/5">
                    {props.children}
                </div>
            </div>
        </section >
    )
}

export default DescriptionSection;