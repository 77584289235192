import React, { FC } from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
interface HomeIndustryCardProps {
    name: string;
    description: string;
    link: string
}
const HomeIndustryCard: FC<HomeIndustryCardProps> = (props) => {
    return (
        <AniLink fade to={props.link} className="p-6 flex flex-col justfy-between max-w-sm p-4 md:max-w-full">
            <div className="x-4">
                <h3 className="font-heading font-heading text-2xl text-grey-darker m-0">{props.name}</h3>
                <p className="mt-4 text-grey-darker mb-12">{props.description}</p>
                <p className="align-middle text-blue-darkest">See Solutions <span className="align-middle"><FontAwesomeIcon icon={faChevronRight} /></span></p>
            </div>
        </AniLink>
    )
}

export default HomeIndustryCard;
