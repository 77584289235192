import React, { FC } from 'react';
import { gaButtonEvent } from '../../services/visitor-info';

import AniLink from 'gatsby-plugin-transition-link/AniLink';
interface PrivacyProps {
  bgColor?: string;
  showCta: boolean;
}

const PrivacyCommitment: FC<PrivacyProps> = (props) => {
  const ctaText = 'Learn More About Our Commitment to Privacy';

  return (
    <section
      id="privacy-commitment"
      className="py-16 min-h-screen flex flex-col justify-center"
      style={{ backgroundColor: props.bgColor }}
    >
      <div className="max-w-container p-4 mx-auto h-full w-full">
        <h2 className="font-heading text-grey-dark font-hairline text-3xl md:text-5xl mb-4">
          Protecting People, Protecting Privacy
        </h2>
        <div className="max-w-lg">
          <p className="text-grey-darker">
            Patronscan is committed to protecting people and their privacy. It is our responsibility
            to protect all patron personal information. We comply with all privacy regulations in
            their respective jurisdictions.
          </p>

          {props.showCta ? (
            <AniLink
              onClick={() => {
                gaButtonEvent(ctaText, 'PrivacyCommitmentCTA');
              }}
              aria-label="industries"
              fade
              className="text-blue hover:text-blue-darkest transition-color mt-4 block"
              to="/privacy"
            >
              Learn More About Our Commitment to Privacy
            </AniLink>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default PrivacyCommitment;
