import React, { FC } from 'react';
import HomeIndustryCard from './home-industry-card';

interface HomeIndustryProps {
  industries: HomeIndustry[];
}

interface HomeIndustry {
  name: string;
  description: string;
  link: string;
}

const HomeIndustry: FC<HomeIndustryProps> = (props) => {
  return (
    <div className="md:mt-48 md:mb-24">
      <div className="flex flex-row flex-wrap items-stretch justify-center mx-auto max-w-container mx-auto">
        {props.industries.map((industry: HomeIndustry) => (
          <div key={industry.name} className="p-4 w-full md:w-1/2 flex flex-row items-stretch">
            <div className="bg-white w-full shadow-md rounded-md hover:shadow-xl transition-shadow">
              <HomeIndustryCard
                name={industry.name}
                description={industry.description}
                link={industry.link}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeIndustry;
