import React, { FC } from 'react';
import Slider from 'react-slick';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './trusted-by.css';
import { Testimonial } from '../../models/testimonial';

interface TrustedByProps {
  testimonials?: Testimonial[];
  backgroundColor?: string;
  logos?: any;
}

const TrustedBy: FC<TrustedByProps> = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "logos/home" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const logoSliderSettings = {
    slidesToShow: 4,
    autoplay: false,
    autoplaySpeed: 3000,
    initialSlide: 10,
    centerMode: true,
    centerPadding: '25px',
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerMode: true,
          centerPadding: '25px',
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          centerMode: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '25px',
          autoplay: true,
        },
      },
    ],
  };

  const testimonialSliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: '200px',
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: '0px',
        },
      },
    ],
  };

  const TestimonialSection = (testimonials?: Testimonial[]) => {
    if (!testimonials) {
      return null;
    }

    return (
      <div>
        <hr className="hidden md:block md:mt-8 border-grey-lighter mx-auto max-w-6xl mb-32" />
        <div className="flex flex-row flex-wrap items-center lg:items-start">
          <div className="md:w-1/2 p-4 w-full lg:px-inset">
            <h3 className="font-body mb-4 text-grey-light font-hairline text-2xl">
              Patronscan has created millions of trustworthy relationships while securing businesses
              across the globe.
            </h3>
          </div>
          <div className="bg-blue-old mt-8 md:mt-0 md:p-6 w-full md:w-1/2">
            <Slider {...testimonialSliderSettings}>
              {props.testimonials
                ? props.testimonials.map((testimonial: Testimonial) => (
                    <blockquote
                      key={testimonial.name}
                      className=" relative inline-block mx-auto my-6 text-white px-8 focus:outline-none"
                    >
                      <div className="md:pl-12">
                        <p className="mt-4">{testimonial.testimonial}</p>
                        <p className="font-bold mt-8">{testimonial.name}</p>
                        <p className="">{testimonial.role}</p>
                      </div>
                    </blockquote>
                  ))
                : null}
            </Slider>
          </div>
        </div>
      </div>
    );
  };
  const logoSlider = (logos: any) => {
    if (props.logos) {
      return (
        <div className="max-w-container mx-auto">
          <h2 className="text-blue text-3xl font-body p-4">Trusted By</h2>
          <div className="my-4 md:my-16">
            <Slider {...logoSliderSettings}>
              {logos.map((logo: any) => (
                <div key={logo.node.name} className="p-4 md:px-4 lg:px-12 focus:outline-none">
                  <Img className="max-w-xs" fluid={logo.node.childImageSharp.fluid} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      );
    }
  };
  const classes = props.logos || props.testimonials ? 'block' : 'hidden';

  return (
    <section
      id="trusted-by"
      className={`mx-auto flex flex-col justify-center min-h-screen py-12 md:py-24 pt-4 pb-4 ${classes}`}
      style={{ backgroundColor: props.backgroundColor ? props.backgroundColor : '#fff' }}
    >
      {logoSlider(props.logos)}
      {TestimonialSection(props.testimonials)}
    </section>
  );
};

export default TrustedBy;
